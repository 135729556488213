body {
    margin: 0;
    /* TODO: import font family to make it more consistent across devices */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

:root {
    --system-gray: #f0f0f0;
    --macos-cyan: rgb(85, 190, 240);
    --macos-blue: rgb(0, 122, 255);
}

.rounded-8px {
    border-radius: 8px;
}

.rounded-18px {
    border-radius: 18px;
}

.smooth-transition {
    transition: all 0.3s ease;
}

.scale:hover {
    transform: scale(1.02);
}

.scale-2:hover {
    transform: scale(1.05);
}

.hover-shadow:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.minimal-link-style {
    color: black;
    text-decoration: none;
}

.bg-system-gray {
    background: var(--system-gray);
}

.mx-width-800 {
    max-width: 800px;
}